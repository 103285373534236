<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-row>
      <b-col
        cols="12"
        sm="4"
      >
        <router-link :to="{ name: 'user.repairs.pending' }">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ requests.pending }}</h2>
                  <p class="fontsize-sm m-0 text-warning">
                    Pending
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-warning">
                  <i class="ri-inbox-fill" /></div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <router-link :to="{ name: 'user.repairs.histories', params: {status: 'Approved'}}">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ requests.approved }}</h2>
                  <p class="fontsize-sm m-0 text-success">
                    Approved
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon dark-icon-light iq-bg-success">
                  <i class="ri-inbox-fill" /></div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <router-link :to="{ name: 'user.repairs.histories', params: {status: 'Disapproved'}}">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ requests.disapproved }}</h2>
                  <p class="fontsize-sm m-0 text-danger">
                    Disapproved
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-danger">
                  <i class="ri-inbox-fill" /></div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <router-link :to="{ name: 'user.repairs.histories', params: {status: 'Cancelled'}}">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ requests.cancelled }}</h2>
                  <p class="fontsize-sm m-0 text-danger">
                    Cancelled
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-danger">
                  <i class="ri-inbox-fill" /></div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <router-link :to="{ name: 'user.repairs.histories', params: {status: 'Completed'}}">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ requests.completed }}</h2>
                  <p class="fontsize-sm m-0 text-info">
                    Completed
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-info">
                  <i class="ri-inbox-fill" /></div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SUserDashboard } from '@/services'

export default {
  name: 'VUserDashboard',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      requests: {
        pending: 0,
        approved: 0,
        disapproved: 0,
        cancelled: 0,
        completed: 0
      }
    }
  },

  mounted () {
    core.index()
    this.fetchStatistics()
  },

  methods: {
    async fetchStatistics () {
      await SUserDashboard.getStatistics().then(({ data }) => {
        this.requests = data
      })
    }
  }
}
</script>
